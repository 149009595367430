import { loading, endLoading } from './loading.action'
import axios from 'axios'
import * as a from '../types/auth.types'
import * as m from '../types/message.types'
import { errorHandler } from '../../utils/errors'

export const setCurrentUser = (user) => (dispatch) => {
    dispatch({ type: a.SET_USER, payload: user })
}

export const login = ({ email, password}) =>
{
    return async (dispatch) => {
        dispatch({ type: m.CLEAR_MESSAGES })
        dispatch(loading('login'))
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}auth/login`, {email, password})
            const data = response.data
            sessionStorage.setItem('user_token', data.token)
            dispatch(setCurrentUser(data.user))
        }
        catch ( error) {
            errorHandler(error)
        }
        dispatch(endLoading('login'))
    }
}


export const logoutUser = () => (dispatch) =>
{
    sessionStorage.removeItem('user_token')
    dispatch(setCurrentUser({}))
}
