import { loading, endLoading } from './loading.action'
import axios from 'axios'
import * as m from '../types/message.types';
import * as c from '../types/client.types';
import { errorHandler } from '../../utils/errors'

export const setClient = (status) => (dispatch) => {
    dispatch({ type: c.GET_CLIENT, payload: status })
}
let token = sessionStorage.getItem('user_token');
const config = {
    headers: { Authorization: `Bearer ${token}` }
};

export const allClients = () => {
    return function (dispatch) {
        dispatch({ type: m.CLEAR_MESSAGES })
        dispatch(loading('allClients'))
        try {   
            return axios.get('http://astrack-backend.astract.com.ng/api/v1/clients', config)
            .then(({ data }) => {
                console.log('============ data in action ========================');
                console.log(data.data);
                console.log('====================================');
                dispatch(setClient(data.data));
            })
        } catch (error) {
            errorHandler(error);
        }

        dispatch(endLoading('allClients'));
    }
}