import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import * as Yup from 'yup'
import { IoCaretBackOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux'

import Button from '../../../components/button';
import { getLoader } from '../../../ducks/selector/loading.selector'
import Input from '../../../components/input';
import Layout from '../../../layout/index.layout';
import { newProject } from '../../../ducks/actions/project.actions';
import './style.css';
import { getStatuses } from '../../../ducks/selector/status.selector';
import { allStatuses } from '../../../ducks/actions/status.actions';
import Select from '../../../components/select';
import { getClients } from '../../../ducks/selector/client.selector';
import { allClients } from '../../../ducks/actions/client.action';

const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    client_id: Yup.string().required().nullable().label('Client'),
    comms_manager_id: Yup.string().required().nullable().label('Manager'),
    description: Yup.string().required().label('Description'),
    communication_date: Yup.date().required().label('First Communication Date'),
    payment_date: Yup.date().required().label('Payment Date'),
    status: Yup.string().required().label('Status'),
    ticket_no: Yup.string().nullable().label('Ticket No')
});

function NewProject() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector(getLoader);

    const status = useSelector(getStatuses);
    const [statusLoaded, setStatusLoaded] = useState(false);
    {!statusLoaded && dispatch(allStatuses()) }

    const client = useSelector(getClients);
    const [clientLoaded, setClientLoaded] = useState(false);
    {!clientLoaded && dispatch(allClients()) };

    const [modal, setModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            client_id: '',
            comms_manager_id: '',
            description: '',
            communication_date: '',
            status: '',
            payment_date: '',
            ticket_no: '',
        },
        onSubmit: (values) => {
            dispatch(newProject(values)).then(
                setModal(true)
            );
            
        },
        validationSchema: validationSchema,
    });

    const returnToProject = () => {
        setModal(false);
        navigate('/projects', { replace: true});
    }

    useEffect(() => {
        status && setStatusLoaded(true) 
        client && setClientLoaded(true)
    }, [client]);


    return(
        <Layout>
            <div className='w-full px-2 lg:px-6'>
                <div className='text-lightgrey flex items-center' >
                    <IoCaretBackOutline className='text-lightgrey' /> <span>Back to Project</span>
                </div>
            </div>   

            <section className='w-full px-2 lg:px-6'>
                <p className='text-[24px] text-[#C2C6C8]'> Create New Project </p>
                <form className='grid grid-cols-2 gap-10' onSubmit={formik.handleSubmit}>
                    <div className='col-span-2'>
                        <Input
                            label="Project Name"
                            type='text'
                            name="name"
                            placeholder="Enter Project Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.name && formik.errors.name ? formik.errors.name : ''
                            }
                        />
                    </div>

                    <div>
                        <Select
                            label='Client'
                            options={client}
                            optionLabel='company_name'
                            name='client_id'
                            value={formik.values.client_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.client_id && formik.errors.client_id ? formik.errors.client_id : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="Ticket No"
                            type='text'
                            name="ticket_no"
                            value={formik.values.ticket_no}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.ticket_no && formik.errors.ticket_no ? formik.errors.ticket_no : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="Comms Manager"
                            type='text'
                            name="comms_manager_id"
                            value={formik.values.comms_manager_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.comms_manager_id && formik.errors.comms_manager_id ? formik.errors.comms_manager_id : ''
                            }
                        />
                    </div>

                    <div>
                        <Select
                            label='Select Status'
                            options={status}
                            optionLabel='name'
                            name='status'
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.status && formik.errors.status ? formik.errors.status : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="First Communication Date"
                            type='date'
                            name="communication_date"
                            value={formik.values.communication_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.communication_date && formik.errors.communication_date ? formik.errors.communication_date : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="Payment Date"
                            type="date"
                            name="payment_date"
                            value={formik.values.payment_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.payment_date && formik.errors.payment_date ? formik.errors.payment_date : ''
                            }
                        />
                    </div>

                    <div className='col-span-2'>
                        <Input
                            label="Description"
                            type='text'
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.description && formik.errors.description ? formik.errors.description : ''
                            }
                        />
                    </div>

                    <div>
                        <Button type='submit' className="bg-primary" title="Create Project" />
                    </div>
                </form>
            </section>

            { modal && 
                <section className="modal">
                    <div className="flex flex-col modalContainer">
                        <button className="self-end mb-10" onClick={() => returnToProject()}>
                            <svg stroke="currentColor" color="#62646C" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="0.7em" xmlns="http://www.w3.org/2000/svg">
                                <path fill="none" strokeWidth="5" d="M3,3 L21,21 M3,21 L21,3" stroke="currentColor"></path>
                            </svg>
                        </button>
                        
                        <svg className="mx-auto mb-5" width="181" height="181" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M50 90.5L77.341 117L132 64" stroke="#06B437" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <circle cx="90.5" cy="90.5" r="90" stroke="#06B437"/>
                        </svg>

                        <div>
                            Project <span className="text-white"> {formik.values.name} </span> has been created successfully
                        </div>
                    </div>
                </section>
            }
        </Layout>
    )
}

export default NewProject