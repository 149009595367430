import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'

const Sidebar = ({ items, className }) =>
{
    const location = useLocation()
    const currentRoute = location.pathname.split('/')[1]

    return(
        <div className={['w-250 flex-col items-center', className].join(' ')}>
            <nav>
                {
                    items.map((item, index) => (
                        <Link 
                            to={item.href} key={index} 
                            className={['flex space-between items-start mb-6 w-full',
                            currentRoute === item.route ? 'bg-primary w-190 rounded-sm h-50 items-center': null].join(' ')}
                        >
                            <img src={item.icon} alt={item.route} className='mr-2' />
                            <span>{item.name}</span>
                        </Link>
                    ))
                }
            </nav>
        </div>
    )
}

export function NavbarMobile ({ items, open, handleClose}){
    const location = useLocation()
    const currentRoute = location.pathname.split('/')[1]
    
    return(
        <div>
            {
                open ?  
                <div className='absolute z-30 top-0 right-0 h-4/5 w-200 p-2 flex justify-center flex-col bg-darkBg2 lg:hidden'>
                    <AiOutlineClose onClick={handleClose} className='absolute top-3 right-3' />
                    {
                        items.map(( item, index) => (
                            <Link 
                                to={item.href} key={index} 
                                className={['flex gap-x-2 py-4', currentRoute=== item.route && 'text-primary font-bold'].join(' ')}
                            >
                                <img src={item.icon} alt={item.route} />
                                <span>{item.name}</span>
                            </Link>
                        ))
                    }
                </div> : null
            }
        </div>
       
    )
}
export default Sidebar
