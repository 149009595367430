import React from 'react'
import Sidebar, { NavbarMobile } from '../components/sidebar'
import { sidebarConstants } from '../utils/constants'
import Header from '../components/header'

const Layout = ({ children }) =>
{
    return(
        <>
            <div className='bg-darkBg1 min-h-screen w-full text-lightgrey lg:pt-6 '>
                <Header />
                <div className='flex w-full'>
                    <Sidebar items={sidebarConstants} className="hidden lg:flex" />
                    <div className='w-full'>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout