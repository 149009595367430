import React from 'react'
import Layout from '../../layout/index.layout'
import PageHeader from '../../components/page-header'
import Content from '../../components/settings/'

const Settings = () => {
    return(
        <Layout>
            <div className='w-full px-2 mb-6 lg:px-6'>
                <PageHeader />
                <Content />
            </div>
        </Layout>
    )
}

export default Settings
