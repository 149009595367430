import { loading, endLoading } from './loading.action'
import axios from 'axios'
import * as m from '../types/message.types';
import * as s from '../types/status.types';
import { errorHandler } from '../../utils/errors'

export const setStatus = (status) => (dispatch) => {
    dispatch({ type: s.GET_STATUS, payload: status })
}
let token = sessionStorage.getItem('user_token');
const config = {
    headers: { Authorization: `Bearer ${token}` }
};

export const allStatuses = () => {
    return function (dispatch) {
        dispatch({ type: m.CLEAR_MESSAGES })
        dispatch(loading('allStatuses'))
        try {   
            return axios.get('http://astrack-backend.astract.com.ng/api/v1/admin/statuses', config)
            .then(({ data }) => {
                dispatch(setStatus(data));
            })
        } catch (error) {
            errorHandler(error);
        }

        dispatch(endLoading('allStatuses'));
    }
}