import React, { useEffect } from 'react'
import Button from '../../../button'
import { useFormik } from 'formik'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getLoader } from '../../../../ducks/selector/loading.selector'
import Input from '../../../input'
import { userState } from '../../../../ducks/selector/auth.selector';
import * as Yup from 'yup';
import { createNewTeam } from '../../../../APIS/team'
import { useNavigate } from 'react-router-dom'
import store from '../../../../ducks/store'



function AddTeam () {
    
    const team = useSelector(state => state.team)
    const message = useSelector(state => state.message)
    const navigate = useNavigate()

  
    useEffect(() => {
        message.success && setTimeout(() => {navigate('/teams')}, 2000) 
    }, [message.success])

    const validationSchema = Yup.object({
        name: Yup.string().required('Team name is required')
    })
    
    const dispatch = useDispatch()
    let loading = useSelector(getLoader)

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: (values) => {
            dispatch(createNewTeam(values))
        },

        validationSchema
    })

    return(
        <div>
           <h1 className="opacity-10 font-semibold text-2xl mt-7 text-lightgrey">Create New Team</h1>
            <form onSubmit={formik.handleSubmit}>
                <div className='p-4 border-2 mt-4 border-input border-opacity-40 mb-10 rounded-md'>
                    <div className='w-full flex flex-col lg:flex-row '>
                        <div className="mb-5 mr-0 md:mb-0 flex-1 md:mr-3">
                            <Input 
                                label='Team Name' 
                                value={formik.values.name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full -mb-4'
                                name='name'
                                error = 
                                {formik.touched.name && formik.errors.name ? formik.errors.name : null }
                            />
                        </div>
                    </div>
                    <Button title="Create Team" className="bg-primary mt-4 text-white" loading={loading} disabled={loading} type='submit' />
                </div>
            </form>
        </div>  
    )
}


export default AddTeam
