import React from "react";

const InfoBox = ({icon, amount, title}) => 
{
    return (

        <div className="relative flex flex-col items-center border-2 border-dark bg-input h-100">
            <img src={icon} alt='icon' className="absolute bottom-20"/>
            <div className=" pt-6 flex flex-col items-center justify-center">
                <h2>{amount}</h2>
                <p>{title}</p>
            </div>
        </div>        
    )
}

export default InfoBox
