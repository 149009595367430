import * as c from '../types/client.types'
import { isEmpty } from 'lodash'

const initialState = {
    client: {},
    isLoaded: false,
}

const client = (state = initialState, action) => 
{
    switch(action.type) {
        case c.GET_CLIENT:
            return {
                ...state,
                client: action.payload,
                isLoaded: !isEmpty(action.payload)
            }
        default:
            return state
    }
}

export default client
