import React from 'react'
import Box from './box'

const ProjectInfoSummary = () =>
{
    return(
        <div>
            {
                !ProjectInfo || ProjectInfo.length === 0 ? <p className='text-red-600'>Nothing to show here</p> :
                <div className='w-full flex flex-col gap-y-3 md:flex-row md:gap-x-3 lg:gap-y-0 justify-between'>
                {
                        ProjectInfo.map((item, index) => (
                            <Box {...item} key={index} />
                        ))
                    }
                </div>
            }
        </div>
    )
}

const ProjectInfo = [
    {
        amount: 100,
        title: 'Projects in Development',
        color: 'border-orange text-orange'
    },
    {
        amount: 100,
        title: 'Open Projects',
        color: 'border-red text-red'
    },
    {
        amount: 100,
        title: 'Projects in Development',
        color: 'text-green border-green'
    }
]

export default ProjectInfoSummary
