import React from 'react'
import Button from '../../button'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as m from '../../../ducks/selector/message.selector'
import { getLoader } from '../../../ducks/selector/loading.selector'
import Input from '../../input'
import { userState } from '../../../ducks/selector/auth.selector'


function ChangePassword () {
    
    const dispatch = useDispatch()
    let loading = useSelector(getLoader)

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
        },
        onSubmit: (values) => {
            console.log(values)
        }
    })

    return(
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className='p-4 border-2 border-input border-opacity-40 mb-10 rounded-md'>
                    <div className='w-full flex flex-col lg:flex-row '>
                        <div className="mb-5 mr-0 md:mb-0 flex-1 md:mr-3">
                            <Input 
                                label='Current Password' 
                                value={formik.values.currentPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full'
                                name='currentPassword'
                            />
                        </div>
                        <div className='flex-1'>
                            <Input
                                label='New Password'
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className='w-full'
                                name="newPassword"
                            />
                        </div>
                        
                    </div>
                </div>
                <Button title="Save Changes" className="bg-primary text-white" loading={loading} disabled={loading} type='submit' />
            </form>
        </div>  
    )
}

export default ChangePassword
