import React from 'react'

const Select = ({label, name, options, optionLabel, value, onChange, onBlur, error, placeholder, className }) =>
{
    return (
        <div className={className}>
            <div>
            <p className="text-secondary font-bold text-md">{label}</p>
            </div>
            <div className='relative'>
                <select
                    className='p-3 border-input rounded-lg outline-none mt-2 bg-input text-lightgrey tracking-wider text-base w-full h-full border focus:outline-none'
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                >
                    <option value=''> ---- </option>
                    {
                        options?.map((item, index) => (
                            <option key={index} value={item.id}>
                                {item[optionLabel]}
                            </option>
                        ))
                    }
                </select>
                {error && <p className="text-primary font-bold text-sm mt-1">{error}</p>}
            </div>
        </div>
    )
}

export default Select
