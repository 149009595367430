export const getSuccess = (state) => {
    return state.message.success
}

export const getError = (state) => {
    return state.message.error
}

export const getMessage = (state) => {
    return state.message.message
}
