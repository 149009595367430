import React, { useEffect } from 'react'
import { ToastContainer, toast as tast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { clearMessage } from '../../ducks/actions/index.action'
import { getError, getSuccess, getMessage } from '../../ducks/selector/message.selector'
import { useDispatch, useSelector } from 'react-redux'
import { uppercaseFC } from '../../utils/helpers'

export const Success = ({ message }) => (
    <div>
		<span style={{ fontSize: 14 }}>😊&nbsp;{uppercaseFC(message)}</span>
	</div>
)

export const Error = ({ message }) => (
	<div>
		<span style={{ fontSize: 14 }}>😔&nbsp;{uppercaseFC(message)}</span>
	</div>
)

export const Info = ({ message }) => (
	<div>
		<span style={{ fontSize: 14 }}>📣&nbsp;{uppercaseFC(message)}</span>
	</div>
)

const toastConfig = {
	hideProgressBar: true,
	duration: 1000,
}


const Toast = () => {
    const dispatch = useDispatch()
    const error = useSelector(getError)
    const success = useSelector(getSuccess)
    const message = useSelector(getMessage)

    useEffect(() => {
        openNotification()
    }, [success, error])

    const openNotification = () => {
        if (success && message ) {
            tast.success(<Success message={message} />, {
                hideProgressBar: true
            })
        }
        if (error && message) {
			tast.error(<Error message={message} />, {
				hideProgressBar: true,
			})
		}
		setTimeout(function () {
			dispatch(clearMessage())
		}, 3000)
    }

    return (
        <>
            <ToastContainer
                autoClose={3000}
                pauseOnHover={true}
                position='top-right'
                transition={Slide} 
            />
        </>
    )
}

export const toast = {
	success: (message, otherConfig) => {
		tast.success(<Success message={message} />, {
			...toastConfig,
			...otherConfig,
		})
	},
	info: (message, otherConfig) => {
		tast.info(<Info message={message} />, {
			...toastConfig,
			...otherConfig,
		})
	},
	error: (message, otherConfig) => {
		tast.error(<Error message={message} />, {
			...toastConfig,
			...otherConfig,
		})
	},
}

export default Toast