import * as p from '../types/project.types'
import { isEmpty } from 'lodash'

const initialState = {
    project: {},
    isLoaded: false,
}

const project = (state = initialState, action) => 
{
    switch(action.type) {
        case p.GET_PROJECTS:
            return {
                ...state,
                project: action.payload,
                isLoaded: !isEmpty(action.payload)
            }
        default:
            return state
    }
}

export default project
