import { loading, endLoading } from './loading.action'
import axios from 'axios'
import * as m from '../types/message.types';
import * as p from '../types/project.types';
import { errorHandler } from '../../utils/errors'

export const setProjects = (project) => (dispatch) => {
    dispatch({ type: p.GET_PROJECTS, payload: project })
}
let token = sessionStorage.getItem('user_token');
const config = {
    headers: { Authorization: `Bearer ${token}` }
};

export const newProject = (values) => {
    return async (dispatch) => {
        dispatch({ type: m.CLEAR_MESSAGES })
        dispatch(loading('newProject'))
        try {
            await axios.post('http://astrack-backend.astract.com.ng/api/v1/admin/projects', values, config)
        }
        catch ( error) {
            errorHandler(error)
        }
        dispatch(endLoading('newProject'))
    }
}

export const allProjects = () => {
    return function (dispatch) {
        dispatch({ type: m.CLEAR_MESSAGES })
        dispatch(loading('allProjects'))
        try {   
            return axios.get('http://astrack-backend.astract.com.ng/api/v1/admin/projects', config)
            .then(({ data }) => {
                dispatch(setProjects(data.projects));
            })
        } catch (error) {
            errorHandler(error);
        }

        dispatch(endLoading('allProjects'));
    }
}

export const singleProject = (id) => {
    return (dispatch) => {
        dispatch({ type: m.CLEAR_MESSAGES })
        dispatch(loading('singleProject'))
        try {
            axios.get('http://astrack-backend.astract.com.ng/api/v1/admin/projects/'+id, config)
            .then(({ data }) => {
                return data.project;
            })
        } catch (error) {
            errorHandler(error);
        }
        
        dispatch(endLoading('singleProject'))
    }
}

export const editProject = (values, slug) => {
    return async (dispatch) => {
        dispatch({ type: m.CLEAR_MESSAGES })
        dispatch(loading('editProject'))
        try {
            console.log('loading');
            await axios.put('http://astrack-backend.astract.com.ng/api/v1/admin/projects/'+slug, values, config)
            console.log('success?');
        }
        catch ( error) {
            errorHandler(error)
        }
        dispatch(endLoading('editProject'))
    }
}