import React, { useState } from 'react'
import FilterComponent from '../filter'
import { FilterBox } from '../filter'
import TableState from './content'
import Input from '../input'

const TeamPageContent = () =>
{
    const [filterBox, setFilterBox] = useState(false)
    
    function toggleFilterBox() {
        setFilterBox(!filterBox)
    }

    function closeFilterBox () {
        setFilterBox(false)
    }
    const [searchText, setSearchText] = useState('')
    return (
        <div className='relative'>
            <div className='flex w-10/12 items-center gap-x-4'>
                <FilterComponent toggleFilterBox={toggleFilterBox} />
                <Input 
                    type='search' value={searchText} 
                    onChange={(e) => setSearchText(e.target.value)} placeholder='search for teams'
                    className='text-secondary bg-darkBg1 rounded-3xl w-full' 
                />
            </div>
            <FilterBox open={filterBox} close={closeFilterBox}/>
            <TableState />
        </div>
    )
}

export default TeamPageContent