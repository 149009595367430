import React, { useState } from 'react'
import Input from '../input'
import FilterComponent, { FilterBox } from '../filter'
import ProjectInfoSummary from './content/projectInfoSummary'
import ProjectInfoTable from './content/projectTable'

const ProjectsPageContent = () =>
{
    const [filterbox, setFilterBox] = useState(false)
    const [searchText, setSearchText] = useState('')

    function toggleFilterBox() {
        setFilterBox(!filterbox)
    }

    function closeFilterBox () {
        setFilterBox(false)
    }

    return (
        <>
            <ProjectInfoSummary />
            <div className='my-6'/>

            <div className='relative'>
                <div className='flex w-10/12 items-center gap-x-4'>
                    <FilterComponent toggleFilterBox={toggleFilterBox} />
                    <Input 
                    type='search' value={searchText} 
                    onChange={(e) => setSearchText(e.target.value)} placeholder='search for orders'
                    className='text-secondary bg-darkBg1 rounded-3xl w-full' 
                />
                </div>
            </div>
            <FilterBox open={filterbox} close={closeFilterBox} />
            <div className='my-6'/>
            <ProjectInfoTable />
            
        </>
    )
}

export default ProjectsPageContent