import React from 'react'
import Layout from '../../layout/index.layout'
import PageHeader from '../../components/page-header'
import Content from '../../components/dashboard'

const Dashboard = () => {
    return(
        <Layout>
            <div className='px-2 lg:px-6'>
                <PageHeader />
                <Content />  
            </div>
        </Layout>
    )
}

export default Dashboard
