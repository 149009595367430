import React, { useState } from 'react'
import { FaEye, FaEyeSlash, FaSearch, FaRegCalendarAlt } from 'react-icons/fa'

const Input = ({
    label,
    placeholder,
    type,
    className,
    value,
    onBlur,
    onChange,
    name,
    error,
    readOnly,
    disabled,
    page,
    icon
}) => {
    const [isVisible, setIsVisible] = useState(false)
    return (
        <div>
            <p className="text-secondary font-bold text-md">{label}</p>
            <div className="relative">
                {
                    type === 'search' &&
                    <FaSearch className='absolute text-secondary top-6 left-3 cursor-pointer' />
                }
                <input
                    className={[type === 'search' ? 'px-8 py-3' : 'p-3' ,'mt-2 bg-input text-lightgrey tracking-wider text-base w-full h-full border border-input rounded-lg focus:outline-none', className].join(' ')}
                    placeholder={placeholder}
					type={isVisible ? 'text' : type}
					value={value}
					onBlur={onBlur}
					onChange={onChange}
					name={name}
					readOnly={readOnly}
					disabled={disabled}
                />
                <div className=' mt-3 flex justify-end'>
                {page === 'login' && (
					<p className="text-white text-sm font-bold cursor-pointer">
						Forgot Password
					</p>
				)}
                </div>
                {
                    icon === 'calendar' && <FaRegCalendarAlt className="absolute text-lightgrey top-5 right-3 cursor-pointer" />
                }
                {type === 'password' &&
					(isVisible ? (
						<FaEyeSlash
							onClick={() => setIsVisible((isVisible) => !isVisible)}
							className="absolute text-lightgrey top-5 right-3 cursor-pointer"
						/>
					) : (
						<FaEye
							onClick={() => setIsVisible((isVisible) => !isVisible)}
							className="absolute text-lightgrey top-5 right-3 cursor-pointer"
						/>
					))}
				{error && <p className="text-primary font-bold text-sm mt-1">{error}</p>}
            </div>
        </div>
    )
}

export default Input
