import dashboardIcon from '../assests/Icons/dashboard.svg'
import projectIcon from '../assests/Icons/projects.svg'
import teamIcon from '../assests/Icons/teams.svg'
import settingsIcon from '../assests/Icons/settings.svg'

export const sidebarConstants = 
[
    {
        name: 'Dashboard',
        route: 'dashboard',
        icon: dashboardIcon,
        href: '/dashboard'
    },
    {
        name: 'Projects',
        route: 'projects',
        icon: projectIcon,
        href: '/projects'
    },
    {
        name: 'Teams',
        route: 'teams',
        icon: teamIcon,
        href: '/teams'
    },
    {
        name: 'Settings',
        route: 'settings',
        icon: settingsIcon,
        href: '/settings'
    }
]
