import React from 'react'
import DashboardDateProjectSummary from './content/date-summary-info'
import DashboardStatistics from './content/statistics'

const DashboardContent = () =>
{
    return(
        <div className='py-6'>
            <DashboardStatistics />
            <div className="my-6" />
            <DashboardDateProjectSummary />
        </div>
    )
}
export default DashboardContent
