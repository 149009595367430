import { combineReducers } from "redux";
import loading from "./loading.reducer";
import message from "./message.reducer";
import auth from "./auth.reducer";
import project from "./project.reducer";
import status from "./status.reduceer";
import client from "./client.reducer";
import team from "./team.reducer";


export default combineReducers({
    loading,
    message,
    auth,
    project,
    status,
    client,
    team
})