import * as t from '../types/auth.types'
import { isEmpty } from 'lodash'

const initialState = {
    user: {},
    isAuthenticated: false
}

const auth = (state= initialState, action) => 
{
    switch(action.type) {
        case t.SET_USER:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: !isEmpty(action.payload)
            }
        default:
            return state
    }
}

export default auth
