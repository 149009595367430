export const formatDate = (date) => {

    date = date.replace(/[.]/g,'-');

    let dateTimeParts= date.split(/[- :]/);
    dateTimeParts[1]--; 

    const dateObject = new Date(...dateTimeParts);

    return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(dateObject);
}

export const htmlDate = (date) => {
    date = date.replace(/[.]/g,'-');

    let dateTimeParts= date.split(/[- :]/);
    dateTimeParts[1]--; 

    const dateObject = new Date(...dateTimeParts);
    return dateObject.toISOString().substring(0, 10);
}