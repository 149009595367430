import React, { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


function DatePickerFunction () {

    let settings = {
        className: 'center',
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplaySpeed: 2000,
        arrows: true,
        focusOnSelect: true,
        responsive: 
        [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    let dateRange = getDates()
    const [selectedDate, setSelectedDate] = useState(new Date().getDate())

    // return (
    //     <div className='bg-input border-2 border-dark px-4 py-6 w-full flex justify-between'>
    //         <Slider {...settings}>
    //             {dateRange.map((date, index) => {
    //                 let date_ = date.getDate()
    //                 const weekday = ["Sun","Mon","Tues","Wed","Thurs","Fri","Sat"];
    //                 let day = weekday[date.getDay()]
    //                 return (
    //                         <div 
    //                             className={
    //                                 [date_ === selectedDate ? 'bg-date bg-opacity-10 border-light border-input border-opacity-10 px-3' : '', 'cursor-pointer'].join(' ')
    //                             } 
    //                             key={index}
    //                             onClick = {() => setSelectedDate(date_)}
    //                         >
    //                             <div className={[date_ === selectedDate ? 'text-white': 'text-secondary', ' font-semibold'].join(' ')}>{day}</div>
    //                             <div className='text-white font-semibold text-center'>{date_}</div>
    //                         </div>
    //                 )
    //             }) }
    //         </Slider>
    //     </div>

    // )
    
    return (
        <div className='bg-input border-2 border-dark px-4 py-6 w-full flex justify-between'>
            
            {dateRange.map((date, index) => {
                let date_ = date.getDate()
                const weekday = ["Sun","Mon","Tues","Wed","Thurs","Fri","Sat"];
                let day = weekday[date.getDay()]
                return (
                        <div 
                            className={
                                [date_ === selectedDate ? 'bg-date bg-opacity-10 border-light border-input border-opacity-10 px-3' : '', 'cursor-pointer'].join(' ')
                            } 
                            key={index}
                            onClick = {() => setSelectedDate(date_)}
                        >
                            <div className={[date_ === selectedDate ? 'text-white': 'text-secondary', ' font-semibold'].join(' ')}>{day}</div>
                            <div className='text-white font-semibold text-center'>{date_}</div>
                        </div>
                )
            }) }
        </div>
    )
}

function getDates() {
    let endRange = getFutureDate()
    let startRange = getPreviousDate()
    let dates = []

    let currentDate = startRange
    const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
    }
    while (currentDate <= endRange) {
        dates.push(currentDate)
        currentDate = addDays.call(currentDate , 1)
    }

    let dateRange = []
    dates.forEach((date) => {
        dateRange.push(date)
    })
    return dateRange
}

function getFutureDate() {
    let currentDate = new Date()
    let startDate = new Date(currentDate)
    startDate.setDate(startDate.getDate() + 4)
    return startDate
}

function getPreviousDate() {
    let currentDate = new Date()
    let endDate = new Date(currentDate)
    endDate.setDate(endDate.getDate() - 4)
    return endDate
}


export default DatePickerFunction