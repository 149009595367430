import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from '../../ducks/actions/auth.actions'
import { LoginSchema } from '../../utils/validator'
import { authState } from '../../ducks/selector/auth.selector'
import { getLoader } from '../../ducks/selector/loading.selector'
import Logo from '../../assests/Astract9 white logo.svg'
import Object from '../../assests/OBJECTS.svg'
import Input from '../../components/input'
import Button from '../../components/button'



const Login = () =>
{
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const loading = useSelector(getLoader)
    const isAuthenticated = useSelector(authState)

    useEffect(() => {
        isAuthenticated && navigate('/')
    }, [isAuthenticated])

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: (values) => {
            dispatch(login(values))
            console.log(loading);
        },
        validationSchema: LoginSchema,
    })

    return(
        <div className='w-full min-h-screen flex flex-row'>
            <div className='w-full bg-darkBg1 px-4 lg:w-1/2 pt-8 md:px-14 lg:pt-10'>
                <div>
                    <img src={Logo} alt='logo' />
                </div>
                <div className='pt-8 sm:pt-16'>
                    <div className='flex flex-col items-center relative'>
                        <h1 className='text-white text-3xl md:text-4xl font-bold pb-2 relative'>Login to Your Account</h1>
                        <p className='text-lightgrey text-sm'>Lorem ipsum dolor sit amet, consectetur </p>
                    </div>
                </div>
                <div className='mt-8'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='flex-1 mb-5'>
                                <Input
                                    label="Email Address"
                                    type='email'
                                    name="email"
                                    placeholder="philiptemplar@gmail.com"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.email && formik.errors.email ? formik.errors.email : ''
                                    }
                                />
                            </div>

                            <div className='flex-1 mb-5'>
                                <Input
                                    label="Password"
                                    type='password'
                                    name="password"
                                    page="login"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.password && formik.errors.password ? formik.errors.password : ''
                                    }
                                />
                            </div>
                            <Button type='submit' className="w-full mt-12 bg-primary" title="Login to your account" />
                        </form>
                    </div>
                </div>
            <div className='hidden lg:flex w-1/2 bg-darkBg2 px-4 pt-10 text-white'>
                <img src={Object} alt='object' className='' />
            </div>
        </div>
    )
}

export default Login
