import React from 'react'
import Layout from '../../layout/index.layout'
import PageHeader from '../../components/page-header'
import Content from '../../components/teams'
import { Outlet } from 'react-router-dom'

const Teams = () => {
    return(
        <>
            <Layout>
                <div className='w-full px-2 lg:px-6'>
                    <PageHeader />
                    <Content />
                </div>
            </Layout>
            <Outlet />
        </>
    )
}

export default Teams
