import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userState } from "../../ducks/selector/auth.selector";
import { logoutUser } from "../../ducks/actions/auth.actions";
import { GiHamburgerMenu,  } from 'react-icons/gi'
import { FaBell } from 'react-icons/fa'
import { CgProfile } from 'react-icons/cg'
import Input from "../input";
import { NavbarMobile } from "../sidebar";
import Logo from '../../assests/Astract9 white logo.svg'
import { sidebarConstants } from '../../utils/constants'

const Header = () => {

    const dispatch = useDispatch()
    const user = useSelector(userState) || {}
    const { firstname, lastname } = user

    const [openMobile, setOpenMobile] = useState(false)

    return (
        <>
            <div className="flex flex-col lg:flex-row w-full space-between pb-6 z-10 relative">
                <div className="w-full flex justify-between items-center pr-2 lg:w-250 flex item-center pb-4 lg:pb-0">
                    <img src={Logo} alt="astract logo" />
                    <GiHamburgerMenu className="block lg:hidden" onClick={() => setOpenMobile(true)} />
                </div>
                <div className="border-b-light w-full border-dark flex justify-between px-2 lg:px-6 items-center">
                    <div className="w-1/2">
                        <Input type='search' />
                    </div>
                    <div className="flex">
                        <FaBell className="text-secondary mr-4" size={20} />
                        <CgProfile className="lg:mr-4" size={20} onClick={() => dispatch(logoutUser())} />
                        <div className="hidden lg:block">
                            <h1 className="font-bold">Hello, {firstname} {lastname}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <NavbarMobile open={openMobile} items={sidebarConstants} handleClose={() => setOpenMobile(false)} />
        </>
        
    )
}

export default Header