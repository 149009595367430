import React, { useState } from 'react';
import filterIcon from '../../assests/Icons/filter.svg'
import { FaWindowClose, FaRegCalendarAlt } from 'react-icons/fa'
import Select from '../select'
import Input from '../input';
import Button from '../button'

const FilterComponent = ({toggleFilterBox }) =>
{
    return(
        <>
            <div className='border-1 border-input py-2 px-4 text-secondary rounded-md relative cursor-pointer' onClick={() => toggleFilterBox()}>
                <div className='flex gap-x-2'>
                    <img src={filterIcon} alt='filter icon' />
                    <p>Filter</p>
                </div>
                
            </div>
        </>
        
    );
}

export function FilterBox ({ open, close }) {
    
   return(
       <div>
           {
            open ? 
            <div className='transition-all duration-500 ease-in-out animation-spin w-300 lg:w-400 absolute mt-2 z-20 bg-darkBg2 border-1 border-lightgrey border-opacity-40 p-4 cursor-pointer'>
                <div className='flex items-center justify-between pb-6'>
                    <h6 className='text-secondary font-bold text-xl'>Filter Options</h6>
                    <FaWindowClose onClick={close} className='text-secondary'/>
                </div>
                <div className='flex flex-col gap-y-6 pb-6'>
                    <Select name="Status" placeholder='Status' className='w-full' />
                    <Input name='date' placeholder='Date' icon='calendar' />
                </div>
                <div className='flex flex-col gap-y-6'>
                    <Button className='border-2 border-primary text-primary p-2' title='Apply Filter' />
                    <Button className='border-2 border-dark text-lightgrey' title='Clear Filter' />
                </div>
            </div> : null
           }
       </div>
   )
        
}

export default FilterComponent