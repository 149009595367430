import React, { useState } from 'react'
import clock from '../../../../../assests/Icons/miniclock.svg'

const ProjectSummaryDisplay = () =>
{
    const [active, setActive] = useState(1)
    return(
        <div className='bg-input border-2 border-dark px-4 py-6'>
            {
                data?.map((item, index) => (
                    <div onClick={() => setActive(item.id)} key={index}>
                        <Box {...item}  active={active} />
                    </div>
                ))
            }
        </div>
    )
}

const data = [
    {
        id: 1,
        title: 'Website Landing Page Redesign', 
        manager: 'Feyidamilola Shopade',
        client: 'BMO and Co',
        time: '3 weeks'
    },
    {
        id: 2,
        title: 'Website Landing Page Redesign', 
        manager: 'Feyidamilola Shopade',
        client: 'BMO and Co',
        time: '3 weeks'
    },
    {
        id: 3,
        title: 'Website Landing Page Redesign', 
        manager: 'Feyidamilola Shopade',
        client: 'BMO and Co',
        time: '3 weeks'
    },
    {
        id: 4,
        title: 'Website Landing Page Redesign', 
        manager: 'Feyidamilola Shopade',
        client: 'BMO and Co',
        time: '3 weeks'
    }
]


function Box ({ active, title, manager, client, time, id }){
    return(
        <div className={[active === id ? ' border-l-darker': 'border-l-left border-l-opacity-10', 'border-l-2 my-4 px-2'].join(' ')}>
            <div 
                className={[ active === id ? 'bg-darker border-darker' : 'bg-input border-input border-opacity-40  opacity-40', 'border-1 border-sm p-4'].join(' ')}
            >
                <h3 className={[ 'text-xls', active === id ? 'text-white font-semibold' : 'text-lightgrey'].join(' ')}>{title}</h3>
                <div className='mt-3 w-full items-start lg:items-center flex justify-between flex-col  lg:flex-row'>
                    <div className='text-xs text-secondary'>
                    Project Manager: <span className={[ 'text-sm', active === id ? 'text-white' : 'text-lightgrey'].join(' ')}> {manager}</span> 
                    </div>
                    <div className='text-xs text-secondary'>
                        Client: <span className={[ 'text-sm', active === id ? 'text-white' : 'text-lightgrey'].join(' ')}>{client}</span>
                    </div>
                    <div className='text-xs text-secondary flex'>
                        <img src={clock} alt='clock' className='mr-2' /> <span className={[ 'text-sm', active === id ? 'text-white' : 'text-lightgrey'].join(' ')}>{time}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectSummaryDisplay
