import * as team_types from "../types/team.types"

export const addTeam = () => {
    return {
        type: team_types.ADD_TEAM,
    }
}

export const fetchTeams = (teams) => {
    return {
        type: team_types.FETCH_ALL_TEAMS,
        payload: teams
    }
}

export const fetchSingleTeam = (team_id) => {
    return {
        type: team_types.FETCH_SINGLE_TEAM,
        payload: team_id
    }
}

export const fetchMembers = (members) => {
    return {
        type: team_types.FETCH_TEAM_MEMBERS,
        payload: members
    }
}