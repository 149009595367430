import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup'
import { IoCaretBackOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import Button from '../../../components/button';
import { getLoader } from '../../../ducks/selector/loading.selector'
import Input from '../../../components/input';
import Layout from '../../../layout/index.layout';
import { errorHandler } from '../../../utils/errors';
import { editProject } from '../../../ducks/actions/project.actions';
import '../newProject/style.css';
import { htmlDate } from '../../../utils/dataformat';
import { getStatuses } from '../../../ducks/selector/status.selector';
import { allStatuses } from '../../../ducks/actions/status.actions';
import Select from '../../../components/select';
import { getClients } from '../../../ducks/selector/client.selector';
import { allClients } from '../../../ducks/actions/client.action';

const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    client_id: Yup.string().required().nullable().label('Client'),
    comms_manager_id: Yup.string().required().nullable().label('Manager'),
    description: Yup.string().nullable().required().label('Description'),
    status: Yup.string().nullable().required().label('Status'),
    communication_date: Yup.date().required().label('First Communication Date'),
    payment_date: Yup.date().required().label('Payment date'),
    start_date: Yup.date().nullable().label('Start date'),
    end_date: Yup.date().nullable().label('End date'),
    first_review: Yup.date().nullable().label('First review'),
    second_review: Yup.date().nullable().label('Second review'),
    third_review: Yup.date().nullable().label('Third review'),
    ticket_no: Yup.string().nullable().required().label('Ticket number'),
});

function EditProject() {
    const navigate = useNavigate();
    const loading = useSelector(getLoader);
    const dispatch = useDispatch();
    
    const [projectDetail, setProjectDetail] = useState(false);
    const [detail, setDetail] = useState({});
    const { slug }  = useParams();

    
    const status = useSelector(getStatuses);
    const [statusLoaded, setStatusLoaded] = useState(false);
    {!statusLoaded && dispatch(allStatuses()) }

    const client = useSelector(getClients);
    const [clientLoaded, setClientLoaded] = useState(false);
    {!clientLoaded && dispatch(allClients()) };

    let token = sessionStorage.getItem('user_token');
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const singleProject = () => {
        console.log('we are here');
        try {
            axios.get('http://astrack-backend.astract.com.ng/api/v1/admin/projects/'+slug, config)
            .then(({ data }) => {
                setDetail(data.project);
                setProjectDetail(true);
            })
        } catch (error) {
            errorHandler(error);
        }
    }

    useEffect(() => {
        !projectDetail && singleProject();
        status && setStatusLoaded(true) 
        client && setClientLoaded(true)
    }, [detail]);

    const formik = useFormik({
        initialValues: {
            name: detail.name,
            client_id: detail.client ? detail.client.id : '',
            comms_manager_id: detail.comms_manager,
            description: detail.description,
            status: detail.status,
            communication_date: detail.communication_date ? htmlDate(detail.communication_date) : '',
            payment_date: detail.payment_date ? htmlDate(detail.payment_date) : '',
            start_date: detail.start_date ? htmlDate(detail.start_date) : '',
            end_date: detail.end_date ? htmlDate(detail.end_date) : '',
            first_review: detail.first_review ? htmlDate(detail.first_review) : '',
            second_review: detail.second_review ? htmlDate(detail.second_review) : '',
            third_review: detail.third_review ? htmlDate(detail.third_review) : '',
            ticket_no: detail.ticket_no || '',
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            // console.log(values)
            dispatch(editProject(values, slug))
        },
        validationSchema: validationSchema,
    });

    return(
        <Layout>
            <div className='w-full px-2 lg:px-6'>
                <button className='text-lightgrey flex items-center' 
                    onClick={() => navigate(-1)}
                >
                    <IoCaretBackOutline className='text-lightgrey' /> <span>Back to Project</span>
                </button>
            </div>   

            <section className='w-full px-2 lg:px-6'>
                <p className='text-[24px] text-[#C2C6C8]'> Edit Project </p>
                <form className='grid grid-cols-2 gap-10' onSubmit={formik.handleSubmit}>
                    <div className='col-span-2'>
                        <Input
                            label="Project Name"
                            type='text'
                            name="name"
                            placeholder="Enter Project Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.name && formik.errors.name ? formik.errors.name : ''
                            }
                        />
                    </div>

                    <div className='col-span-2'>
                        {/* <Input
                            label="Client"
                            type='text'
                            name="client_id"
                            placeholder="Enter or Search for Project Owner"
                            value={formik.values.client_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.client_id && formik.errors.client_id ? formik.errors.client_id : ''
                            }
                        /> */}
                        <Select
                            label='Client'
                            options={client}
                            optionLabel='company_name'
                            name='client'
                            value={formik.values.client_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.status && formik.errors.status ? formik.errors.status : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="Ticket no"
                            type='text'
                            name="ticket_no"
                            value={formik.values.ticket_no}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.ticket_no && formik.errors.ticket_no ? formik.errors.ticket_no : ''
                            }
                        />
                    </div>

                    <div>
                        <Select
                            label='Status'
                            options={status}
                            optionLabel='name'
                            name='status'
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.status && formik.errors.status ? formik.errors.status : ''
                            }
                        />
                        {/* <Input 
                            label="Status"
                            type="text"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.status && formik.errors.status ? formik.errors.status : ''
                            }
                        /> */}
                    </div>

                    <div>
                        <Input
                            label="Comms Manager"
                            type='text'
                            name="comms_manager_id"
                            value={formik.values.comms_manager_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.comms_manager_id && formik.errors.comms_manager_id ? formik.errors.comms_manager_id : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="First Communication Date"
                            type='date'
                            name="communication_date"
                            value={formik.values.communication_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.communication_date && formik.errors.communication_date ? formik.errors.communication_date : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="Payment Date"
                            type='date'
                            name="payment_date"
                            value={formik.values.payment_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.payment_date && formik.errors.payment_date ? formik.errors.payment_date : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="Start Date"
                            type='date'
                            name="start_date"
                            value={formik.values.start_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.start_date && formik.errors.start_date ? formik.errors.start_date : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="First Review"
                            type='date'
                            name="first_review"
                            value={formik.values.first_review}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.first_review && formik.errors.first_review ? formik.errors.first_review : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="Second Review"
                            type='date'
                            name="second_review"
                            value={formik.values.second_review}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.second_review && formik.errors.second_review ? formik.errors.second_review : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="Third Review"
                            type='date'
                            name="third_review"
                            value={formik.values.third_review}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.third_review && formik.errors.third_review ? formik.errors.third_review : ''
                            }
                        />
                    </div>

                    <div>
                        <Input
                            label="End Date"
                            type='date'
                            name="end_date"
                            value={formik.values.end_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.end_date && formik.errors.end_date ? formik.errors.end_date : ''
                            }
                        />
                    </div>

                    <div className='col-span-2'>
                        <Input
                            label="Description"
                            type='text'
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.description && formik.errors.description ? formik.errors.description : ''
                            }
                        />
                    </div>

                    <div>
                        <Button type='submit' className="bg-primary" title="Create Project" />
                    </div>
                </form>
            </section>
        </Layout>
    )
}

export default EditProject