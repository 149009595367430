import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router,  Route, Routes, Navigate } from 'react-router-dom'
import Idletimer from '../utils/sessionhandler'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../ducks/actions/auth.actions'
import history from '../utils/history'
import ProtectedRoute from './protected.routes'
import Dashboard from '../pages/dashboard'
import { PageLoader } from "../components/loading-component"
import Error404 from '../components/Error404'
import Login from '../pages/Auth/Login'
import Project from '../pages/projects'
import Settings from '../pages/settings'
import Teams from '../pages/teams'
import NewProject from '../pages/projects/newProject'
import NewTeam from '../pages/teams/new-team'
import TeamData from '../pages/teams/team-data'
import NewMember from '../pages/member/new-member'
import EditProject from '../pages/projects/editProject'
import ProjectDetails from '../pages/projects/projectDetails'


const AppRoute = () => {

    const dispatch = useDispatch()

    // useEffect(() => {
    //     const timer = new Idletimer({
    //         timeout: 300,
    //         onTimeout: () => {
    //             dispatch(logoutUser())
    //         },
    //         onExpired: () => {
    //             dispatch(logoutUser())
    //         }
    //     })
    //     return () => {
    //         timer.cleanUp();
    //     }
    // }, [])

    return (
        <Router history={history}>
            <Suspense fallback={<PageLoader />}>
                <Routes>
                    <Route path="/login" exact element={ <Login /> } />
                    <Route element={<ProtectedRoute />}>
                        <Route path="/dashboard" element={ <Dashboard /> } />
                        <Route path="/" element={<Navigate replace to="/dashboard" />} />
                        <Route path="/projects" exact element={ <Project /> } />
                        <Route path='/projects/new-project' exact element={<NewProject />}/>
                        <Route path='/projects/:slug/edit-project' exact element={<EditProject />}/>
                        <Route path='/projects/:slug/project-details' exact element={<ProjectDetails/>}/>
                        <Route exact path="/teams" element={ <Teams /> } />
                        <Route path='/teams/add-team' element={<NewTeam />}/>
                        <Route path='/teams/:teamId' element={<TeamData />}/>
                        <Route  path="/members" element={ <NewMember /> } />
                        <Route exact path="settings" element={ <Settings /> } />
                        <Route path="*" element={<Error404 />} />
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Route>
                </Routes>
            </Suspense>
        </Router>
    )
}

export default AppRoute