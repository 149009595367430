import axios from 'axios';
import { addTeam, fetchTeams, fetchMembers } from '../../ducks/actions/team.actions';
import { errorHandler } from '../../utils/errors';
import * as m from '../../ducks/types/message.types'
import _ from 'lodash'

import APIURL from '..';
import { loading, endLoading } from '../../ducks/actions/loading.action';
import { clearMessage, getSuccess } from '../../ducks/actions/message.actions';


export const createNewTeam = (form) => {
    return async (dispatch) => {
        dispatch(clearMessage())
        dispatch(loading())
        try 
        {
            const result = await APIURL().post('/admin/teams', form)
            dispatch(getSuccess(result.data.message))

        } 
        catch(error) {
            errorHandler(error)
        }
        dispatch(clearMessage())
        dispatch(endLoading())
    }
}

export const fetchAllTeams = () => {
    return async (dispatch) => {
        try {
            const response = await APIURL().get('/admin/teams');
            dispatch(fetchTeams(response.data.teams))
        } catch (error) {
        }
    }
}

export const fetchTeamMembers = (team_id) => {
    return async (dispatch) => {
        try {
            const response = await APIURL().get(`/admin/teams/${team_id}/members`);
            console.log(response.data.team.users);
            dispatch(fetchMembers(response.data.team.users))
        } catch (error) {
            errorHandler(error)  
        }
    }
}


