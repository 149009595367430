import React from "react"
import { ButtonLoader } from "../loading-component"

const Button = ({ title, className, onClick, disabled, loading, type }) => {
	return (
		<button
			className={`text-white px-7 py-2 text-sm rounded-md font-normal flex items-center justify-center ${[
				disabled && 'bg-gray-400',
				className,
			].join(' ')}`}
			onClick={onClick}
			disabled={disabled}
			type={type}
		>
			{loading ? <ButtonLoader /> : title}
		</button>
	)
}

export default Button