import * as Yup from 'yup'

const addMemberValidation = Yup.object({
    team_name: Yup.string().required('Team name is required'),
    firstname: Yup.string().required('Firstname is required').min(4),
    lastname: Yup.string().required('Firstname is required').min(4),
    email: Yup.string().required('Email is required').email("Should be an email format"),
    phone: Yup.number().required('Phone number is required').min(11),
    password: Yup.string().required('Password is required'),
    password_confirmation: Yup.string().required('Password is required')
     .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

export default addMemberValidation