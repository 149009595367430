import React from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../button'
import Input from '../../../input'
import { getLoader } from '../../../../ducks/selector/loading.selector'
import addMemberValidation from '../../../../form-validation/add-member'

const AddMember = () =>
{
    const dispatch = useDispatch()
    let loading = useSelector(getLoader)

    const formik = useFormik({
        initialValues: {
            team_name: '',
            firstname:'',
            lastname: '',
            email: '',
            password: '',
            phone: '',
            password_confirmation: ''
        },
        onSubmit: (values) => {
            console.log(values)
        },
        validationSchema: addMemberValidation
    })

    console.table(formik.values);


    return(
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className='p-4 border-2 border-input border-opacity-40 mb-10 rounded'>
                    <div className='w-full flex flex-col lg:flex-row mb-4'>
                        <div className="mb-5 mr-0 md:mb-0 flex-1 md:mr-3">
                            <Input 
                                label='Team Name' 
                                value={formik.values.team_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full -mb-4'
                                name='team_name'
                                error = {
                                  formik.touched.team_name && formik.errors.team_name ? formik.errors.team_name : null 
                                }
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-col lg:flex-row mb-4'>
                        <div className="mb-5 mr-0 md:mb-0 flex-1 md:mr-3">
                            <Input 
                                label='First Name' 
                                value={formik.values.firstname}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full -mb-4'
                                name='firstname'
                                error = {
                                    formik.touched.firstname && formik.errors.firstname ? formik.errors.firstname : null 
                                }
                            />
                        </div>
                        <div className='flex-1'>
                            <Input
                                label='Last Name'
                                value={formik.values.lastname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className='w-full -mb-4'
                                name="lastname"
                                error = {
                                    formik.touched.lastname && formik.errors.lastname ? formik.errors.lastname : null 
                                }
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-col lg:flex-row mb-4'>
                        <div className="mb-5 mr-0 md:mb-0 flex-1 md:mr-3">
                            <Input 
                                label='Email Address'
                                type='email'
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full -mb-4'
                                name='email'
                                error = {
                                    formik.touched.email && formik.errors.email ? formik.errors.email : null 
                                }
                            />
                        </div>
                        <div className='flex-1'>
                            <Input 
                                label='Mobile Number' 
                                value={formik.values.phone}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full -mb-4'
                                name='phone'
                                type='tel'
                                error = {
                                    formik.touched.phone && formik.errors.phone ? formik.errors.phone : null 
                                }
                            />
                        </div>
                    </div>

                    <div className='w-full flex flex-col lg:flex-row mb-4'>
                        <div className="mb-5 mr-0 md:mb-0 flex-1 md:mr-3">
                            <Input 
                                label='Password'
                                type='password'
                                value={formik.values.password}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full -mb-4'
                                name='password'
                                error = {
                                    formik.touched.password && formik.errors.password ? formik.errors.password : null 
                                }
                            />
                        </div>
                        <div className='flex-1'>
                            <Input 
                                label='Confirm Password' 
                                value={formik.values.password_confirmation}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full -mb-4'
                                name='password_confirmation'
                                type='password'
                                error = {
                                    formik.touched.password_confirmation && formik.errors.password_confirmation ? formik.errors.password_confirmation : null 
                                }
                            />
                        </div>
                    </div>
                </div>
                <Button title="Add Member" className="bg-primary text-white" loading={loading} disabled={loading} type='submit' />
            </form>   
        </div>
        
    )
}

export default AddMember