import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => (
    <div>
        <div>
            <h3>Page not found, 🔗</h3>
            <p className="mt-16">
                Oops! It seems you&apos;ve hit a dead end. Your requested link is
                broken, please head back.
            </p>
            <Link to="/dashboard" className="mt-32">
                Go to Home
            </Link>
        </div>
    </div>
)

export default Error404
