import React, { useState } from 'react'
import DatePickerFunction from './date-picker'
import ProjectSummaryDisplay from './summary-info'
import GraphStatistics from '../statistics/graph-statistics'
import Select from '../../../select'

const DashboardDateProjectSummary = () =>
{
    const [day, setDay] = useState('')
    const [month, setMonth] = useState('')

    return(
        <div className='w-full flex flex-col gap-4 lg:flex-row'>
            <div className='w-full lg:w-3/5 border-2 border-input p-4'>
                <DatePickerFunction />
                <div className='my-6'/>
                <ProjectSummaryDisplay />
            </div>
            <div className='w-full lg:w-2/5 border-2 border-input p-4'>
                <div className='border-b-2 border-input'>
                    <div className='flex justify-between items-center'>
                        <h3 className='font-semibold text-xl '>Daily Statistics</h3>
                        <Select
                            options={dailyOptions}
                            name="filterDay"
                            value={day}
                        />
                    </div>
                    {
                        !graphData || graphData.length === 0 ? <div className='py-6 text-center text-secondary'>There is nothing to display here</div> : <GraphStatistics data={graphData} />
                    }
                </div>


                <div className='py-6'>
                    <div className='flex justify-between items-center'>
                        <h3 className='font-semibold text-xl'>Monthly Statistics</h3>
                        <Select
                                options={monthlyOptions}
                                name="filterMonth"
                                value={month}
                            />
                    </div>
                    {
                        !graphData || graphData.length === 0 ? <div className='py-6 text-center text-secondary'>There is nothing to display here</div> : <GraphStatistics data={graphData} />
                    }
                </div>
               
            </div>
        </div>
    )
}

const graphData = [
    {
        label: 'Closed',
        value: 30,
    },
    {
        label: 'Pending',
        value: 45
    },
    {
        label: 'Overdue',
        value: 25
    }
]

const dailyOptions = [
    {
        label: 'Please select an option',
        value: ''
    },
    {
        label: 'Today',
        value: 'Today',
    },
    {
        label: 'Yesterday',
        value: 'Yesterday'
    },
    {
        label: 'Last Week',
        value: 'Last Week'
    }
]


const monthlyOptions = [
    {
        label: 'Please select an option',
        value: ''
    },
    {
        label: 'January',
        value: 'January',
    },
    {
        label: 'Ferburary',
        value: 'Ferburary'
    },
    {
        label: 'March',
        value: 'March'
    },
    {
        label: 'April',
        value: 'April'
    },
    {
        label: 'May',
        value: 'May'
    },
    {
        label: 'June',
        value: 'June'
    },
    {
        label: 'July',
        value: 'July'
    },
    {
        label: 'August',
        value: 'August'
    },
    {
        label: 'September',
        value: 'September'
    },
    {
        label: 'October',
        value: 'October'
    },
    {
        label: 'November',
        value: 'November'
    },
    {
        label: 'December',
        value: 'December'
    },
]

export default DashboardDateProjectSummary
