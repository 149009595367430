import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);


const GraphStatistics = ({ data }) =>
{
    const label = []
    let dataValues = []
    data.forEach( data => {
        label.push(data.label)
        dataValues.push(data.value)
    })
    
    const options = {
        responsive: true,
        plugins: {
            legend: {
              display: false
            },
            datalabels: {
              display: true,
              color: "white",
              formatter: (value) => { return value + '%'}
            },
        }
    }

    return(
        <div className='py-6'>
            <Pie
                data={{
                    labels: label,
                    datasets: [
                        {
                            data: dataValues,
                            backgroundColor: ['#009746', '#9C1D29', '#C78A37'],
                            borderColor: ['#009746', '#9C1D29', '#C78A37'],
                            borderWidth: 1,
                        }
                    ],
                }}
                plugins={[ChartDataLabels]}
                options={options}
                width={40}
                height={40}
            />
        </div>
    )
}

export default GraphStatistics
