import React from 'react'
import { Triangle, Oval } from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export const PageLoader = () => {
    return(
        <div className='flex h-screen justify-center items-center'>
            <Triangle color="#282A36" height="80" width="80" />
        </div>
    )
}

export const ButtonLoader = () => {
	return (
		<div className="px-4">
			<Oval type="Oval" color="#000" width="15" height="15" />
		</div>
	)
}