import { CLEAR_MESSAGES, GET_ERRORS, GET_SUCCESS } from "../types/message.types"

export const clearMessage = () => {
    return {
        type: CLEAR_MESSAGES
    }
}

export const getSuccess = (message) => {
    return {
        type: GET_SUCCESS,
        payload: message
    }
}

export const getErrors = (errors) => {
    return {
        type: GET_ERRORS,
        payload: errors
    }
}