import store from './ducks/store';
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import AppRoute from "./routes/index.routes";
import { PageLoader } from "./components/loading-component/index"
import Toast from './components/toast';

function App() {
  const persistor = persistStore(store)
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<PageLoader />}>
        <AppRoute />
      </PersistGate>
      <Toast />
    </Provider>
  );
}

export default App;
