import axios from "axios";

let BaseAPI = axios.create({
    baseURL: 'http://astrack-backend.astract.com.ng/api/v1'
});

let APIURL = () => {
    let token = sessionStorage.getItem("user_token");

    if (token) {
        BaseAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return BaseAPI;
}

export default APIURL;