import * as t from '../types/message.types'

const initialState = {
    error: false,
    success: false,
    message: ''
}

const message = (state=initialState, action) => {
    switch (action.type) {
        case t.GET_ERRORS:
            return {
                ...state,
                error: true,
                message: action.payload,
                success: false
            }
        case t.GET_SUCCESS:
            return {
                ...state,
                error: false,
                message: action.payload,
                success: true
            }
        case t.CLEAR_MESSAGES:
            return {
                ...state,
                error: false,
                message: action.payload,
                success: false
            }
        default:
            return state
    }
}

export default message