import React from 'react'

function Box ({ amount, title, color }) {
    return(
        <div className={`border-2 ${color} p-6 w-full text-center`}>
            <p>{amount} {title}</p>
        </div>
    )
}

export default Box