import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'
import { authState } from '../ducks/selector/auth.selector'

function ProtectedRoute ({ element: Component, ...rest }) {

    const isAuthenticated = useSelector(authState)
    return isAuthenticated ? <Outlet /> : <Navigate to='/login' />
}

export default ProtectedRoute
