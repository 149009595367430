import React from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as m from '../../../ducks/selector/message.selector'
import { getLoader } from '../../../ducks/selector/loading.selector'
import { userState } from '../../../ducks/selector/auth.selector'
import Input from '../../input'
import Button from '../../button'

const ProfileSettings = () =>
{
    const dispatch = useDispatch()
    let loading = useSelector(getLoader)
    const user = useSelector(userState) || []

    const formik = useFormik({
        initialValues: {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            username: user.username ? user.username : '',
            mobilenumber: user.phone ? user.phone : '',
        },
        onSubmit: (values) => {
            console.log(values)
        }
    })

    return(
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className='p-4 border-2 border-input border-opacity-40 mb-10 rounded'>
                    <div className='w-full flex flex-col lg:flex-row mb-4'>
                        <div className="mb-5 mr-0 md:mb-0 flex-1 md:mr-3">
                            <Input 
                                label='First Name' 
                                value={formik.values.firstname}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full'
                                name='firstname'
                            />
                        </div>
                        <div className='flex-1'>
                            <Input
                                label='Last Name'
                                value={formik.values.lastname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className='w-full'
                                name="lastname"
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-col lg:flex-row mb-4'>
                        <div className="mb-5 mr-0 md:mb-0 flex-1 md:mr-3">
                            <Input 
                                label='Email Address'
                                type='email'
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full'
                                name='email'
                            />
                        </div>
                        <div className='flex-1'>
                            <Input
                                label='Username'
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className='w-full'
                                name="username"
                            />
                        </div>
                    </div>
                    <div className=' w-full lg:w-1/2 flex flex-col lg:flex-row mb-4'>
                        <div className="mb-5 mr-0 md:mb-0 flex-1 md:mr-3">
                            <Input 
                                label='Mobile Number' 
                                value={formik.values.mobilenumber}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='w-full'
                                name='mobilenumber'
                                type='tel'
                            />
                        </div>
                    </div>
                </div>
                <Button title="Save Changes" className="bg-primary text-white" loading={loading} disabled={loading} type='submit' />
            </form>   
        </div>
        
    )
}

export default ProfileSettings