import * as team_types from '../types/team.types'

const initialState = {
    teams: [],
    team: [],
    members: []
}

const team = (state = {}, action) => {
    switch (action.type) {
        case team_types.ADD_TEAM:
            return {
                ...state,
            }
        case team_types.FETCH_ALL_TEAMS:
            return {
                ...state,
                teams: action.payload
            }
        case team_types.FETCH_SINGLE_TEAM: 
            return {
                ...state,
                team: state.teams.find(team => team.id === action.payload)
            }
            case team_types.FETCH_TEAM_MEMBERS:
                return {
                    ...state,
                    members: action.payload
                }
        default:
            return state
    }
}

export default team;