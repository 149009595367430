import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import InfoBox from '../box'
import completed from '../../../../assests/Icons/completed.svg'
import hold from '../../../../assests/Icons/hold.svg'
import request from '../../../../assests/Icons/request.svg'
import review from '../../../../assests/Icons/review.svg'

const DashboardStatistics = () =>
{
    const settings = {
        dots: true,
		infinite: true,
		speed: 500,
		lazyload: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		autoplay: false,
		autoplaySpeed: 2000,
		cssEase: 'linear',
		arrows: false,

        responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					centerMode: true,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToScroll: 2,
					slidesToShow: 2,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					centerMode: true,
					autoplay: false,
				},
			},
		],
    }

	const data = [
		{ title: 'Completed Projects', icon: completed, amount: 100},
		{ title: 'Projects on Hold', icon: hold, amount: 60 },
		{ title: 'Sent for First Review', icon: review, amount: 31 },
		{ title: 'Website Request', icon: request, amount: 12 },
		{ title: 'Projects in development', icon: review, amount: 43 }
	]

	// return (
	// 	<div className='container px-2 py-6 lg:px-6 lg:py-12 lg:w-10/12 sm:w-11/12 md:py-10'>
	// 		<Slider {...settings}>
	// 			{data?.map((item, index) => (
	// 				<div key={index} className='mt-8' >
	// 					{/* <div className='text-white'>
	// 						{item.title}
	// 						{item.amount}
	// 					</div> */}
	// 					<div>
	// 						<InfoBox {...item} />
	// 					</div>
						
	// 				</div>
	// 			))}
	// 		</Slider>
	// 	</div>
	// )

    return(
		<div>
			{
				!data || data.length === 0 ? <div className='text-center'>No Statistics Avaliable for display</div> : 
				 <div className='grid gap-3 grid-cols-auto'>
					{data?.map((item, index) => (
						<div className='mt-0 md:mt-4' key={index}>
							<InfoBox {...item} />
						</div>	
	 	 			))}
		 		</div>
			}
		</div>
        
    )
}

export default DashboardStatistics
