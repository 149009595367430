import React from 'react'
import Content from '../../../components/teams/content/team-data'
import { IoCaretBackOutline } from 'react-icons/io5'
import Layout from '../../../layout/index.layout'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

function TeamData() {
    const navigate = useNavigate()
  return (
    <Layout>
            <div className='w-full px-2 lg:px-6'>
                <div className='text-lightgrey flex items-center ' >
                    <IoCaretBackOutline className='text-lightgrey' /> <span  className='cursor-default' onClick={() => navigate(-1)} >Back to Team</span>
                </div>
                <Content></Content>
            </div>   
        </Layout>
  )
}

export default TeamData