import React, { useEffect } from 'react'
import { IoAdd } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllTeams, fetchTeamMembers } from '../../../../APIS/team'
import { fetchSingleTeam } from '../../../../ducks/actions/team.actions'
import {useParams} from 'react-router-dom';
import { uppercaseFC } from '../../../../utils/helpers'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'


const columns = 
[
    {
        name: 'ID',
        selector: (row) => row.id
    },
    {
        name: 'Firstname',
        selector: (row) => row.firstname,
    },
    {
      name: 'Lastname',
      selector: (row) => row.lastname,
    },
    {
        name: 'Email',
        selector: (row) => row.email,
    },
    {
        name: 'Phone',
        selector: (row) => row.phone
    },
    {
        name: 'Role',
        selector: (row) => row.role
    },
    {
      name: 'Status',
      selector: (row) => row.status
    },
    {
        name: 'Actions',
        selector: (row) => row.action
    }
    
]


function Content() {
    const dispatch = useDispatch()

    const team_data = useSelector(state => state.team)
    const team = team_data.team
    const members = team_data.members

    const linkStyle = {
      textDecoration: "none",
      color: 'white',
      backgroundColor: 'black',
      padding: '4px 8px',
      radius: '4px 4px 4px 4px'
    };

    members.forEach(member => Object.assign((member), { action: <Link style={linkStyle} to={`${member.id}`}>edit</Link>, sortable: true,
    right: true, }))


    const {teamId} = useParams();

    useEffect(() => {
      dispatch(fetchSingleTeam(parseInt(teamId)));
      dispatch(fetchTeamMembers(Number(teamId)))
    }, [])

    return (
    <div>
        <div className='flex justify-between items-center'>
            <h1 className="font-semibold text-3xl mt-7 text-lightgrey">{ team_data && uppercaseFC(`${team.team_name} Team`) }</h1>
            <div className='flex space-x-1 items-center pl-6 bg-primary w-190 rounded-md h-50'><IoAdd /><Link to='/members'>Invite Members</Link></div>
        </div>
        <div className='relative  z-10 mt-6 bg-darkBg1'>
            <DataTable 
             title="Team Members"
             columns={columns} 
            data = {members}
             pagination 
            />
        </div>

    </div>
  )
}

export default Content