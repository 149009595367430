import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllTeams } from '../../../APIS/team'
import _ from 'lodash'
import { Link } from 'react-router-dom'

const columns = 
[
    {
        name: 'ID',
        selector: (row) => row.id
    },
    {
        name: 'Name',
        selector: (row) => row.team_name,
    },
    {
        name: 'No of Members',
        selector: (row) => row.no_of_members,
    },
    {
        name: 'No of projects',
        selector: (row) => row.no_of_projects
    },
    {
        name: 'Status',
        selector: (row) => row.status
    },
    {
        name: 'Actions',
        selector: (row) => row.action
    }
    
]


const TableState = () =>
{
    const linkStyle = {
        textDecoration: "none",
        color: 'white',
        backgroundColor: 'black',
        padding: '4px 8px',
        radius: '4px 4px 4px 4px'
    };

    const dispatch = useDispatch()
    const teams = useSelector(state => state.team.teams)
    console.log(teams);
    teams.forEach(team => Object.assign((team), { action: <Link style={linkStyle} to={`${team.id}`}>show</Link>, sortable: true,
    right: true, }))

    useEffect(() => {
        dispatch(fetchAllTeams())
    }, [])
    
    
   
    return(
        <div className='relative  z-10 mt-6 bg-darkBg1'>
            <DataTable 
             title=""
             columns={columns} 
             data = {teams}
             pagination 
            />
        </div>
    )
}

export default TableState;
