import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ChangePassword from './content/changePassword'
import ProfileSettings from './content/profileSettings'
import 'react-tabs/style/react-tabs.css'

function SettingsContent () {
    return(
        <div className='w-full md:w-10/12 lg:w-9/12'>
            <Tabs>
                <TabList className="flex text-lg w-11/12 lg:w-10/12 cursor-pointer text-lightgrey gap-x-10">
                    <Tab 
                        className="opacity-10 "
                        selectedClassName='opacity-70 font-semibold'
                    >
                        Profile Settings
                    </Tab>
                    <Tab className="opacity-10" 
                        selectedClassName='opacity-70 font-semibold'>Change Password</Tab>
                </TabList>
                <div className='mt-6'>
                    <TabPanel>
                        <ProfileSettings />
                    </TabPanel>
                    <TabPanel>
                        <ChangePassword />
                    </TabPanel>
                </div>
                
            </Tabs>
            
        </div>
    )
}

export default SettingsContent
