import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { uppercaseFC } from '../../utils/helpers'
import Button from '../button'
import { AiOutlinePlus } from 'react-icons/ai'

const PageHeader = ({ onClick }) =>
{
    const location = useLocation()
    const currentRoute = location.pathname.split('/')[1]

    console.log(currentRoute)

    return(
        <div className='flex flex-col lg:flex-row justify-between mb-6'>
            <h2 className='text-3xl text-lightgrey font-bold text-opacity-30 lg: pb-4'>{uppercaseFC(currentRoute)}</h2>
            
            {
                currentRoute !== 'settings' ? currentRoute === 'projects' ? 
                <Link 
                    to='/projects/new-project' 
                    className='bg-primary flex gap-x-3 font-semibold items-center justify-center p-2 rounded-sm'
                >
                    <AiOutlinePlus />
                    Create New Project
                </Link> :
                <Button 
                    onClick={ onClick } 
                    title={
                        (
                            <><AiOutlinePlus /> 
                                <span className='pl-2'>
                                    {
                                        currentRoute === 'teams' 
                                        ? 
                                        <Link 
                                        to='add-team' 
                                        className='bg-primary flex gap-x-3 font-semibold items-center justify-center p-2 rounded-sm'
                                        > 
                                            Create New Team
                                        </Link>
                                        : 
                                        'Create New'
                                    }
                                </span> 
                            </> 
                        )
                    } 
                    className='text-md bg-primary' 
                /> 
                : null
            }
            
        </div>
    )
}

export default PageHeader