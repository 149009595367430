import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { allProjects } from '../../../../ducks/actions/project.actions';
import { getProjects } from '../../../../ducks/selector/project.selector';
import { formatDate } from '../../../../utils/dataformat';
import '../../style.css';

function ProjectInfoTable () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectsObject = useSelector(getProjects);
    const [projectsLoaded, setProjectsLoaded] = useState(false);
    {!projectsLoaded && dispatch(allProjects()) }
    let index = 1;

    const columns = 
    [
        {
            name: 'S/N',
            selector: (row) => index++
        },
        {
            name: 'Ticket No',
            selector: (row) => row.ticket_no || '------'
        },
        {
            name: 'Name',
            selector: (row) => row.name || '------',
        },
        {
            name: 'Status',
            selector: (row) => row.status || '------',
        },
        {
            name: 'First Comm Date',
            selector: (row) => row.communication_date ? formatDate(row.communication_date) : '------',
        },
        {
            name: 'Payment Date',
            selector: (row) => row.payment_date ? formatDate(row.payment_date) : '------',
        },
        {
            name: 'Start Date',
            selector: (row) => row.start_date ? formatDate(row.start_date) : '------'
        }
    ];

    useEffect(() => {
        { projectsObject && setProjectsLoaded(true) }
    }, [projectsObject]);

    return(
        <div className='relative z-10 mt-6 bg-darkBg1'>
            { projectsLoaded &&
                <DataTable 
                    columns={columns} 
                    data={projectsObject} 
                    pagination 
                    onRowClicked={(data) => navigate('/projects/'+data.id+'/project-details')}
                /> 
            }
        </div>
    )
}

export default ProjectInfoTable
